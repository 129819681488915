import * as React from "react"
import { graphql } from "gatsby"

import * as styles from "./docs.module.css"
import { i18n } from "../../translate/i18n"

import Layout from "../../components/layout"
import Grid from "../../components/grid"

import Privacy from "../../docs/Privacy.pdf"
import TermsAndConditions from "../../docs/TermsAndConditions.pdf"
import CookiePolicy from "../../docs/CookiePolicy.pdf"

function Docs({ location }) {
  return (
    <Layout location={location} headerHome={false}>
      <Grid>
        <h1 className={styles.page_title}>
          {i18n.t("startingProject.strDocs")}
        </h1>
        <ol className={styles.content}>
          <li className={`${styles.row} ${styles.grey_background}`}>
            <a className={styles.link} href="/docs/portal">
              04/2023 - {i18n.t("docs.portal")}
            </a>
          </li>
          <li className={styles.row}>
            <a className={styles.link} href="/docs/protection">
              04/2023 - {i18n.t("docs.dataProtection")}
            </a>
          </li>
          <li className={`${styles.row} ${styles.grey_background}`}>
            <a className={styles.link} href={Privacy}>
              04/2023 - {i18n.t("docs.privacy")}
            </a>
          </li>
          <li className={styles.row}>
            <a className={styles.link} href={TermsAndConditions}>
              04/2023 - {i18n.t("docs.termsAndConditions")}
            </a>
          </li>
          <li className={`${styles.row} ${styles.grey_background}`}>
            <a className={styles.link} href={CookiePolicy}>
              04/2023 - {i18n.t("docs.cookiePolicy")}
            </a>
          </li>
        </ol>
      </Grid>
    </Layout>
  )
}

export default Docs

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          description
        }
      }
    }
  }
`
